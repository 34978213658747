@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: transparent;
}

::view-transition-image-pair(root) {
  isolation: auto;
}

::view-transition-old(root),
::view-transition-new(root) {
  animation: none;
  mix-blend-mode: normal;
  display: block;
}

body {
  font-family: 'Quicksand', Arial, sans-serif;
  font-display: swap;
  vertical-align: baseline;
  overscroll-behavior-y: contain;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100dvh;
}

/* Height Fix Start */

.h-fix {
  height: calc(100% - 56px);
}

@media (min-width: 768px) {
  .h-fix {
    height: 100%;
  }
}

/* Height Fix End */

@media (hover: none) {
  .touch {
    display: none;
  }
}

.bg-mal {
  background-color: #2f52a2;
}

.min-w-1px {
  min-width: 1px;
}

/* .img {
  background-image: url("background.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
} */

/*.react-modal-sheet-backdrop {
  background-color: black !important;
}*/
.react-modal-sheet-container {
  width: 100% !important;
  padding-top: 0px !important;
  border-radius: 16px 16px 0px 0px !important;
  box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 0.1),
    0px 0px 4px -2px rgb(0 0 0 / 0.1) !important;
  /*box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;*/
  z-index: 50;
}

@media (min-width: 768px) {
  .react-modal-sheet-container {
    left: calc((100% - 720px) / 2) !important;
    right: calc((100% - 720px) / 2) !important;
    width: 720px !important;
  }
}

.header-clip {
  clip-path: circle(300% at 50% -380%);
}

@media (min-width: 768px) {
  .header-clip {
    clip-path: circle(300% at 50% -913%);
  }
}


.spikes {
  position: relative;
  background: #2c3e50;
  height: 192px;
}

.spikes::after {
  content: '';
  position: absolute;
  right: 0;
  left: -0%;
  top: 100%;
  z-index: 10;
  display: block;
  height: 100px;
  background-size: 100px 100%;
  background-image: linear-gradient(135deg, #2c3e50 25%, transparent 25%), linear-gradient(225deg, #2c3e50 25%, transparent 25%);
  background-position: 0 0;
}


.text-ellipsis-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-wrap-balance {
  text-wrap: balance;
}


.bottom-nav {
  box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 0.1), 0 -1px 2px -1px rgb(0 0 0 / 0.1);
}


.ratio-1 {
  aspect-ratio: 1;
}


/* .test {
  visibility: collapse;
  scale: 1 0;

  animation-duration: .3s;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-timing-function: linear;

  -webkit-animation-duration: .3s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-name: fadeIn;
  -webkit-animation-timing-function: linear;
}

@keyframes fadeIn {
  0% {
    visibility: collapse;
    opacity: 0;
    scale: 1 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
    scale: 1 1;
  }
} */



.react-colorful {
  width: 100% !important;
}



.arrow {
  pointer-events: none;
  z-index: 99;
}

.arrow__path {
  stroke: #aaa;
  fill: transparent;
  stroke-dasharray: 6 6;
  stroke-width: 2px;
}

.arrow__head line {
  stroke: #aaa;
  stroke-width: 2px;
}



.shine {
  background-image: linear-gradient(to right, #00000000 0%, #ffffff 20%, #00000000 40%, #00000000 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  background-blend-mode: soft-light;

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.shimmer {
  background-image: linear-gradient(to right, #00000000 0%, #ffffff 20%, #00000000 40%, #00000000 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  /*display: inline-block;
  position: relative;*/

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.dark .dark\:shimmer {
  background-image: linear-gradient(to right, #00000000 0%, #6b7280 20%, #00000000 40%, #00000000 100%);
  background-repeat: no-repeat;
  background-size: 800px 100%;
  /*display: inline-block;
  position: relative;*/

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}



.scale-in {
  scale: 0;

  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-name: scale-in-keyframes;

  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-animation-name: scale-in-keyframes;
}

@keyframes scale-in-keyframes {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}



::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #ff000000;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}